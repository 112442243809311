import React from "react"
import Main from "./Home/Main"

class Home extends React.Component {
  render() {
    return (
      <div>
          <Main/>
      </div>
    )
  }
}

export default Home;
